import axios from '@axios'
import JwtService from './jwtService'

function setupJwt() {
  const jwt = new JwtService(axios)

  return {
    jwt,
  }
}

const { jwt } = setupJwt()
export default jwt
